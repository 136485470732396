<template>
  <div class="container-fluid" id="main_content">
    <div class="row">
      <div class="col-md-7">
        <h4 class="font-weight-bold text-left d-flex mt-3 align-items-baseline">
          ภาพจำลองสถานการณ์อนาคตของจังหวัด{{ currentProvince.name_th }}
          <div
            class="text-gray text-medium pointer ml-3"
            v-tooltip="{
              content:
                'ข้อเขียนการคาดการณ์ภาพฉายอนาคตด้านพลังงานสำหรับจังหวัด บนพื้นฐานของสถานการณ์ปัจจุบันผนวกกับสมมติฐานแรงขับเคลื่อน (driving forces) ด้านต่าง ๆ ในระดับมาก-น้อยต่าง ๆ',
            }"
          >
            <u>ข้อมูลเพิ่มเติม</u>
          </div>
        </h4>
        <transition mode="out-in" name="fade">
          <router-view class="animated" />
        </transition>
        <!--<div id="svg-container"></div>-->
      </div>

      <div class="col-md-5 side-shadow overflow-hidden">
        <affix
          class="px-4 mt-3"
          relative-element-selector="#main_content"
          :offset="{ top: 0, bottom: 0 }"
          :scrollAffix="false"
        >
          <div class="d-flex justify-content-between">
            <div class="pointer text-blue" @click="showGuidingModal()">
              <u>ดูคำแนะนำ</u>
            </div>
            <router-link
              :to="{ name: 'peno.targets' }"
              class="btn btn-outline-red"
            >
              ไปต่อ (เป้าประสงค์ด้านพลังงาน)
              <eva-icon name="arrow-ios-forward" fill="currentColor"></eva-icon>
            </router-link>
          </div>
          <form
            class="mt-3"
            action=""
            method="post"
            @submit.prevent="submitScenario"
            v-promise-btn="{ action: 'submit' }"
          >
            <div class="form-group">
              <label
              >โปรดสรุปสาระสำคัญเกี่ยวกับภาพจำลองสถานการณ์ของจังหวัด
              </label>
              <trumbowyg
                ref="inputScenario"
                v-model="formScenario.text"
                :config="config"
                class=""
                id="inputScenario"
              ></trumbowyg>
            </div>
            <div class="text-right mb-4">
              <button type="submit" class="btn btn-action mr-2" v-show="allowEdit">
                บันทึกและไปต่อ
              </button>
              <!--<button type="submit" class="btn btn-outline-red">-->
              <!---->
              <!--</button>-->
            </div>
          </form>
        </affix>
      </div>
    </div>
    <modals-container></modals-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import Form from '../../../modules/form/form';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingScenarios from '@/components/guiding/GuidingScenarios.vue';

export default {
  name: 'scenario',
  data() {
    return {
      config: {
        semantic: false,
        tagsToRemove: ['script', 'link'],
        autogrow: true,
        autogrowOnEnter: true,
        btns: [
          ['fontsize'],
          ['fontfamily'],
          ['underline', 'italic', 'bold'],
          ['table'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['image'],
          ['fullscreen'],
        ],
        btnsDef: {
          image: {
            dropdown: ['insertImage', 'upload'],
            ico: 'insertImage',
          },
        },
        plugins: {
          table: {
            styler: 'table table-bordered',
          },
          fontfamily: {
            fontList: this.$fontfamilyList,
          },
          upload: {
            serverPath: null,
            fileFieldName: 'image',
            urlPropertyName: 'url',
            statusPropertyName: 'success',
          },
        },
      },
      formScenario: new Form({
        data: () => {
          return {
            text: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {},
      }),
    };
  },
  created() {
    this.config.plugins.upload.serverPath = `/api/media/report/${this.currentReport.id}/scenarios`;
    this.config.plugins.upload.headers = {
      'X-CSRF-TOKEN': this.meta.csrfToken,
    };
  },
  mounted() {
    if (this.currentReport.scenario) {
      this.formScenario.text = this.currentReport.scenario.content;
      this.formScenario.cleanDirty();
    }

    window.onbeforeunload = () => {
      if (this.formScenario.isDirty()) {
        return 'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!';
      } else {
        return void 0;
      }
    };
    // axios.get('/tempsvg').then(({ data }) => {
    //   var svgContainer = document.getElementById('svg-container');
    //   svgContainer.innerHTML = data;
    //   // set event listener
    // });
  },
  beforeDestroy() {
    window.onbeforeunload = null;
  },
  computed: {
    ...mapState('peno', ['currentReport']),
    ...mapGetters('peno', ['currentProvince', 'allowEdit']),
    ...mapState(['meta']),
  },
  methods: {
    ...mapMutations('peno', ['updateScenario']),
    submitScenario() {
      return this.formScenario
        .post('/api/scenarios')
        .then((scenario) => {
          this.updateScenario(scenario);
          this.$router.push({
            name: 'peno.targets',
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showGuidingModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingScenarios,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.formScenario.isDirty()) {
      const answer = window.confirm(
        'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!'
      );
      if (answer) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.affix {
  width: inherit;
  max-width: inherit;
  padding-right: 2.7rem !important;
}
</style>
