<template>
  <div>
    <p>
      <strong>รูปแบบตัวอักษรและตาราง</strong>
    </p>
    <p>
      ผู้ใช้งานสามารถคัดลอกข้อความหรือตารางจากโปรแกรมอื่น เช่น MS Word, PDF
      มาวางในกล่องข้อความได้ เมื่อวางแล้ว หากตัวหนังสือไม่ใช่
      <strong>TH Sarabun New</strong>
      ท่านสามารถเลือกเปลี่ยนรูปแบบตัวหนังสือให้เป็น
      <strong>TH Sarabun New</strong> ได้
    </p>
    <p>
      <strong>การแทรกภาพในกล่องข้อความ</strong><br />
      <strong>กรณีมีรูปภาพที่ต้องการจากคอมพิวเตอร์</strong>
    </p>
    <p>
      ผู้ใช้งานสามารถอัพโหลดภาพที่บันทึกไว้ในเครื่องคอมพิวเตอร์เข้าไปในกล่องข้อความได้ด้วยการกดปุ่ม
      “Choose File”
      จากนั้นหน้าจอจะปรากฏหน้าต่างแสดงโฟลเดอร์ของคอมพิวเตอร์ขึ้นมาให้ผู้ใช้งานคลิกเลือกรูปภาพที่ต้องการจะแทรกลงไปในกล่องข้อความ
      จากนั้นกดปุ่ม “Open” เพื่อยืนยันการเลือกภาพนั้น จากนั้นกดปุ่ม “Confirm”
      เพื่อยืนยันการแทรกรูปภาพ หรือกด “Cancel” เพื่อยกเลิกการแทรกรูปภาพ
    </p>
    <p>
      <strong
        >กรณีมีรูปภาพที่ต้องการจากอินเทอร์เน็ต เช่น รูปภาพที่บันทึกไว้ใน Google
        Drive หรือ Google Photo
      </strong>
    </p>
    <p>
      ผู้ใช้งานสามารถแทรกรูปภาพได้โดยใช้ลิงค์ URL
      ของภาพที่ต้องการแล้ววางลิงค์ดังกล่าวลงไปในช่อง “URL” จากนั้นกดปุ่ม
      “Confirm” เพื่อยืนยันการแทรกรูปภาพ หรือกด “Cancel”
      เพื่อยกเลิกการแทรกรูปภาพ
    </p>
  </div>
</template>

<script>
export default {
  name: 'GuidingCommonEditor',
};
</script>

<style scoped></style>
