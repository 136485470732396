<template>
  <div class="p-4">
    <guiding-common-editor />
  </div>
</template>

<script>
import GuidingCommonEditor from '@/components/guiding/GuidingCommonEditor.vue';

export default {
  name: 'guiding-scenarios',
  components: {
    GuidingCommonEditor,
  },
};
</script>
